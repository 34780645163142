.sweet-overlay {
  background: rgba(10, 10, 10, 0.6); }

.sweet-alert {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  background-color: hsl(211, 15%, 33%);
  padding: 24px;
  padding-top: 64px;
  padding-bottom: 13px;
  border-radius: 0;
  box-shadow: 0 0 14px rgba(0, 0, 0, 0.24), 0 14px 28px rgba(0, 0, 0, 0.48); }
  .sweet-alert h2 {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: auto;
    font-weight: 400;
    color: #f0e9df;
    margin: 20px 0;
    font-size: 1.2em;
    line-height: 1.25;
    text-align: left;
    padding: 0 24px; }
  .sweet-alert p {
    display: block;
    text-align: center;
    color: #f0e9df;
    font-weight: 400;
    font-size: 14px;
    margin: 20px 0; }
  .sweet-alert button {
    border-radius: 0px;
    border: 1px solid #2b3e50;
    box-shadow: none !important;
    background: none !important;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: normal;
    padding: 8px 16px;
    position: relative;
    margin-top: 0; }
    .sweet-alert button:hover, .sweet-alert button:focus {
      background-color: #2b3e50 !important; }
    .sweet-alert button.confirm {
      border: 0px;
      color: hsl(24, 79%, 48%); }
    .sweet-alert button.cancel {
      color: hsl(0, 0%, 92%); }
      .sweet-alert button.cancel:focus {
        box-shadow: none !important; }
      .sweet-alert button:disabled {
          background-color: transparent !important;
      }
  .sweet-alert .sa-icon:not(.sa-custom) {
    transform: scale(0.8);
    margin-bottom: -10px;
    margin-top: -10px; }
  .sweet-alert input {
    border: none;
    border-radius: 0;
    border-bottom: 1px solid #c9c9c9;
    color: #212121;
    margin-bottom: 8px;
    padding: 1px;
    padding-bottom: 8px;
    height: auto;
    box-shadow: none;
    font-size: 13px;
    margin: 10px 0; }
    .sweet-alert input:focus {
      border: none;
      border-bottom: 1px solid #3c80f6;
      box-shadow: inset 0 -1px 0 #3c80f6; }
  .sweet-alert fieldset {
    padding: 0; }
    .sweet-alert fieldset .sa-input-error {
      display: none; }
  .sweet-alert .sa-error-container {
    display: none;
    background: none;
    height: auto;
    padding: 0 24px;
    margin: 0 -20px;
    text-align: left; }
    .sweet-alert .sa-error-container.show {
      padding: 0 24px;
      display: block; }
      .sweet-alert .sa-error-container.show ~ fieldset input {
        background: red;
        border-bottom: 1px solid #d9453c;
        box-shadow: inset 0 -1px 0 #d9453c; }
    .sweet-alert .sa-error-container .icon {
      display: none; }
    .sweet-alert .sa-error-container p {
      color: #d9453c;
      margin-top: 0; }

@-webkit-keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg), scale(0.5);
    -webkit-transform: rotateX(100deg), scale(0.5);
    opacity: 0; }

  100% {
    transform: rotateX(0deg), scale(0.5);
    -webkit-transform: rotateX(0deg), scale(0.5);
    opacity: 1; } }

@keyframes animateErrorIcon {
  0% {
    transform: rotateX(100deg), scale(0.5);
    -webkit-transform: rotateX(100deg), scale(0.5);
    opacity: 0; }

  100% {
    transform: rotateX(0deg), scale(0.5);
    -webkit-transform: rotateX(0deg), scale(0.5);
    opacity: 1; } }

.sweet-alert .sa-icon.sa-success .sa-fix {
    background-color: hsl(211, 15%, 33%);
}

.sweet-alert .sa-icon.sa-success::before, .sweet-alert .sa-icon.sa-success::after {
    background-color: hsl(211, 15%, 33%);
}

.sweet-alert .sa-icon.sa-success {
    border: 0px;
    border-color: transparent;
}

.sweet-alert .la-ball-fall {
    margin-top: -8px;
}

.sa-button-container {
  text-align: right;
}